import React, { createContext, ReactNode, useEffect, useState } from 'react';

type TrackPreviewContextModel = {
  trackPreviewUrl?: string;
  setTrackPreviewUrl: (value?: string) => void;
};

export const TrackPreviewContext = createContext<TrackPreviewContextModel | undefined>(undefined);

const TrackPreviewProvider = ({ children }: { children: ReactNode }) => {
  const [trackPreviewUrl, setTrackPreviewUrl] = useState<string | undefined>();
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>();

  useEffect(() => {
    if (audio && audio.paused) {
      audio.play();
      audio.addEventListener('ended', () => {
        setTrackPreviewUrl(undefined);
      });
    }
  }, [audio]);

  useEffect(() => {
    if (trackPreviewUrl) {
      if (audio && audio.src !== trackPreviewUrl) {
        audio.pause();
        audio.currentTime = 0;
        setAudio(new Audio(trackPreviewUrl));
      } else if (!audio) {
        setAudio(new Audio(trackPreviewUrl));
      }
    } else if (trackPreviewUrl === undefined) {
      audio?.pause();
      setAudio(undefined);
    }
  }, [audio, trackPreviewUrl]);

  const contextValue: TrackPreviewContextModel = {
    trackPreviewUrl: trackPreviewUrl,
    setTrackPreviewUrl: setTrackPreviewUrl,
  };

  return <TrackPreviewContext.Provider value={contextValue}>{children}</TrackPreviewContext.Provider>;
};

export default TrackPreviewProvider;
