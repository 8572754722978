import { createContext } from 'react';

type PayloadType = 'error' | 'success' | 'info';
type SnackbarType = 'OPEN_SNACKBAR' | 'CLOSE_SNACKBAR';

export type NestedSnackbarPayloadModel = {
  message?: string;
  open?: boolean;
  type?: PayloadType | string;
};

export type SnackbarPayloadModel = {
  message?: string;
  open?: boolean;
  type?: PayloadType | string;
  snackbar?: NestedSnackbarPayloadModel;
};

export type SnackbarModel = {
  type: SnackbarType;
  payload?: SnackbarPayloadModel;
};

export type SnackbarContextModel = {
  open?: boolean;
  snackbar: SnackbarPayloadModel;
  dispatchSnackbar: (value: SnackbarModel) => void;
};

const AppContext = createContext<SnackbarContextModel>({} as SnackbarContextModel);

export default AppContext;
